import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import Button from "../Button";
import TikTok from "./fi-social-tiktok.png";
import Instagram from "./fi-social-instagram.png";

const StyledNav = styled.nav`
  background-color: #ffffff;
  padding: 10px;
  margin-top: 55px;
  @media only screen and (max-width: 750px) {
    padding-top: 35px;
  }
`;

const NavButton = styled(Button)`
  background: #552d43;
  color: white;
  text-transform: none;
  height: 30px;
  margin-right: 16px;
`;

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background: white;
  @media only screen and (max-width: 750px) {
    z-index: 1;
    position: absolute;
    flex-direction: column;
    justify-content: flex-end;
    top: 150px;
    border-bottom: 1px solid black;
    padding-bottom: 16px;
    left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    transition: left 0.3s ease;
  }
`;

const StyledLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
  font-size: 17px;
  &:nth-child(2) {
    margin-right: 130px;
    @media only screen and (max-width: 750px) {
      margin-right: 0;
    }
  }

  &:nth-child(3) {
    margin-left: 130px;
    @media only screen and (max-width: 750px) {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 750px) {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: 0;
    margin-left: 0;
  }
`;

const NavLink = styled(Link)`
  color: #83454f;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 5px 10px;
  &:hover {
    text-decoration: underline;
  }
  ${({ selected }) =>
    selected &&
    css`
      pointer-events: none;
      border-color: #d19e47;
    `}
`;

const CenteredLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HamburgerIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 50px;
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  div {
    width: 100%;
    height: 3px;
    background-color: #552d43;
    transition: all 0.3s ease;
  }

  &:hover div {
    background-color: #552d43;
  }

  @media only screen and (min-width: 750px) {
    display: none;
  }
`;

const Icons = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  gap: 8px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 750px) {
    top: 25px;
    right: 45px;
  }
`;

export const SocialImage = styled(Link)`
  width: 38px;
  height: 38px;
  background-size: 38px 38px;
  text-decoration: none;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: contain;
  background-repeat: no-repeat;
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      window.addEventListener("keydown", handleEscape);
    }
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <StyledNav>
      <Icons>
        <NavButton
          target="_blank"
          to="https://app.paperbell.com/checkout/packages/76357"
        >
          book now
        </NavButton>
        <SocialImage
          target="_blank"
          image={Instagram}
          to="https://www.instagram.com/hippie_heart_intuition"
        />
        <SocialImage
          target="_blank"
          image={TikTok}
          to="https://www.tiktok.com/@hippie_heart"
        />
      </Icons>
      <HamburgerIcon
        onClick={toggleMenu}
        aria-label={isOpen ? "Close menu" : "Open menu"}
        aria-expanded={isOpen ? "true" : "false"}
      >
        <div></div>
        <div></div>
        <div></div>
      </HamburgerIcon>
      <CenteredLinksContainer>
        <StyledUl isOpen={isOpen}>
          <StyledLi>
            <NavLink
              selected={location.pathname === "/about"}
              to="/about"
              onClick={closeMenu}
            >
              About Me
            </NavLink>
          </StyledLi>
          <StyledLi>
            <NavLink
              selected={location.pathname === "/tall-poppies"}
              to="/tall-poppies"
              onClick={closeMenu}
            >
              Tall Poppies
            </NavLink>
          </StyledLi>
          <StyledLi>
            <NavLink
              selected={location.pathname === "/coaching"}
              to="/coaching"
              onClick={closeMenu}
            >
              Coaching
            </NavLink>
          </StyledLi>
          <StyledLi>
            <NavLink
              selected={location.pathname === "/contact"}
              to="/contact"
              onClick={closeMenu}
            >
              Contact Me
            </NavLink>
          </StyledLi>
        </StyledUl>
      </CenteredLinksContainer>
    </StyledNav>
  );
};

export default Navbar;
