import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : 'none')};
  background-repeat: no-repeat;
  min-height: 100%;
  ${({ centerBackground }) =>
    centerBackground
      ? css`
          background-size: contain;
          background-position: center;
        `
      : css`
          background-size: cover;
        `}
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0')};
`;

export const FlourishImage = styled.div`
  position: absolute;
  bottom: -60px;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 120px;
  background-image: ${({ flourish }) => (flourish ? `url(${flourish})` : 'none')};
  background-size: contain;
  background-repeat: no-repeat;
`;

const Tier = ({ backgroundImage, flourish, marginTop, centerBackground, children, ...rest }) => {
  return (
    <Container backgroundImage={backgroundImage} marginTop={marginTop} centerBackground={centerBackground} {...rest}>
      {children}
      {flourish && <FlourishImage flourish={flourish} />}
    </Container>
  );
};

export default Tier;
