import React from 'react';
import styled from 'styled-components';
import Hero from './hero.png';
import BottomImage from './bottom-image.jpeg';
import Tier from '../../components/Tier';
import Flourish1 from './flourish1.svg';
import Flourish2 from './flourish2.svg';
import Moon from './moon.svg';

import {
  CenterContainer,
  InfoTier,
  InfoTierHeader,
  InfoContent,
  InfoContentContainer,
  FullWidthContainer,
  LeftContainer,
} from '../../components/Containers';
import Button from '../../components/Button';

const GuideContainer = styled(CenterContainer)`
  flex-direction: column;
`;

const MoonImage = styled.img`
  margin-top: 20px;
`;

function About() {
  return (
    <>
      <Tier backgroundImage={Hero} flourish={Flourish1}>
        <LeftContainer>
          <h2>hi, I'm Alex Dodd your intuitive career coach</h2>
          <p>
            I've climbed the ladders in diverse industries like tech, finance,
            and entertainment, learning the secrets of building success (and
            knowing when to gracefully exit). Now, I'm on a mission to share
            that wisdom and guide you towards a career that sparks your soul and
            sets your spirit alight.
          </p>
          <p>
            I empower badass women to connect with their hippie heart
            (intuition), unleash their inner fire and craft careers that ignite
            their souls. I get a thrill seeing brilliant women like you own
            their power and watch their dreams take flight. It's not just a job,
            it's a calling, and that's exactly how I found my own path after
            years of searching.
          </p>
        </LeftContainer>
      </Tier>
      <InfoTier flourish={Flourish2}>
        <InfoTierHeader>
          your intuitive guide to a fulfilling career
        </InfoTierHeader>
        <GuideContainer>
          <h2>imagine yourself...</h2>
          <InfoContentContainer>
            <InfoContent>
              <p>
                waking up excited about the work you do and the impact you make
              </p>
            </InfoContent>
            <InfoContent>
              <p>
                leaving behind the burnout and frustration of unfulfilling jobs
              </p>
            </InfoContent>
            <InfoContent>
              <p>
                owning your unique talents and confidently navigating your
                career trajectory
              </p>
            </InfoContent>
          </InfoContentContainer>
          <Button to='/coaching'>LEARN MORE ABOUT COACHING</Button>
        </GuideContainer>
      </InfoTier>
      <Tier backgroundImage={BottomImage}>
        <FullWidthContainer>
          <h2>
            your purpose-driven career awaits. unplug the noise and tune into
            your intuition.
          </h2>
          <p>
            The world and all its demands are so loud and overwhelming we easily
            lose touch with our intuition and its power.
          </p>
          <p>
            Intuition (your hippie heart) is always there and ready to guide
            whenever we call on it.
          </p>
          <p>
            Are you ready to unleash your intuition, follow your hippie heart
            and ignite your career?
          </p>
          <MoonImage src={Moon} alt="moon" />
        </FullWidthContainer>
      </Tier>
    </>
  );
}

export default About;
