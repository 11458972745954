import React from 'react';
import Tier from '../../components/Tier';
import Hero from './hero.png';
import BottomImage from './bottom-image.jpeg';
import styled from 'styled-components';
import LargeParagraph from '../../components/LargeParagraph';
import Flourish1 from './flourish1.svg';
import Flourish2 from './flourish2.svg';
import Button from '../../components/Button';
import {
  CenterContainer,
  InfoTier,
  InfoTierHeader,
  InfoContent,
  FullWidthContainer,
  RowContainer,
} from '../../components/Containers';

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 50px;
  background: rgba(250, 250, 250, 0.85);
  margin: 95px 95px 95px auto;
  max-width: 31%;
  @media only screen and (max-width: 750px) {
    margin: 20px 30px 80px 30px;
    max-width: 100%;
  }
`;

const TopTier = styled(Tier)`
  @media only screen and (max-width: 1100px) {
    background-position-x: 40%;
  }
  @media only screen and (max-width: 750px) {
    background-position-x: 20%;
  }
`;

const Home = () => {
  return (
    <>
      <TopTier backgroundImage={Hero} flourish={Flourish1}>
        <RightContainer>
          <h2>welcome to a different kind of coaching.</h2>
          <p>
            I guide "tall poppies" (ambitious women) to rise higher, using
            powerful intuition and actionable wisdom from my 20+ years in the
            corporate arena. Together, we will approach your career and personal
            growth from a different perspective.
          </p>
          <LargeParagraph>
            intuition informed, results oriented:<br></br>
            <b>elevate your career with clarity</b>
          </LargeParagraph>
        </RightContainer>
      </TopTier>
      <InfoTier flourish={Flourish2}>
        <InfoTierHeader>intuition + expertise = career clarity</InfoTierHeader>
        <CenterContainer>
          <RowContainer>
          <InfoContent>
            <h2>craft a career that sparks your soul</h2>
            <p>
              Do you talk about your career with a contagious enthusiasm that
              lights up the room?
            </p>
            <p>
              If you rolled your eyes at that statement, I get it, but career
              and joy shouldn't be strangers.
            </p>
            <p>Let's rewrite the narrative together.</p>
          </InfoContent>
          <InfoContent>
            <h2>unblock your brilliance</h2>
            <p>
              Passionate about your career, but can't see the next step? Do you
              feel there’s an insurmountable hurdle blocking your growth?
            </p>
            <p>Don't let a hidden career ceiling dim your light.</p>
            <p>Let’s shatter that ceiling and illuminate your next move.</p>
          </InfoContent>
          <InfoContent>
            <h2>from exhaustion to exhilaration</h2>
            <p>
              Stuck in the burnout cycle? You think you’ve finally defeated it,
              then a few months later it’s back.
            </p>
            <p>
              Burnout is like the hydra from Greek myth – chop off one head, two
              more grow back.
            </p>
            <p>Let's slay this beast together.</p>
            </InfoContent>
     
            </RowContainer>
          <Button to='/coaching'>LEARN MORE ABOUT COACHING</Button>
        </CenterContainer>
      </InfoTier>
      <Tier backgroundImage={BottomImage}>
        <FullWidthContainer>
          <h2>
            your purpose-driven career awaits. unplug the noise and tune into
            your intuition.
          </h2>
          <p>
            The world and all its demands are so loud and overwhelming we easily
            lose touch with our intuition and its power.
          </p>
          <p>
            Intuition (your hippie heart) is always there and ready to guide
            whenever we call on it.
          </p>
          <p>
              Are you ready to unleash your intuition, follow your hippie heart
            and ignite your career?
          </p>
        </FullWidthContainer>
      </Tier>
    </>
  );
};

export default Home;
