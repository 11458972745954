import React from "react";
import styled from "styled-components";
import heartbeatsImage from "./heartbeats.png";
import Button from '../Button';

const FooterContainer = styled.footer`
  width: 100%;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  padding: 20px 0;
  background-color: #552d43;
  padding-bottom: 40px;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CopyrightText = styled.p`
  margin: 20px 0;
  padding-bottom: 100px;
  color: #ccc;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  img {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 0;
  }
  span {
    font-size: 15px;
    color: #552d43;
    @media only screen and (max-width: 750px) {
      display: none;
    }
  }
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0;
`;

const LineLeft = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  width: calc(50% - 100px);
  height: 2px;
  background-color: #9c9387;
`;

const LineRight = styled.div`
  position: absolute;
  top: -15px;
  right: 0;
  width: calc(50% - 100px);
  height: 2px;
  background-color: #9c9387;
`;

const Image = styled.img`
  width: 200px;
  height: auto;
  margin-top: -115px;
  z-index: 1;
`;

const EmailSignup = styled(Button)`
  margin-top: 12px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Button target="_blank" to="https://app.paperbell.com/checkout/packages/76357">LET'S GET STARTED</Button>
        <EmailSignup target="_blank" to="https://lp.constantcontactpages.com/sl/AzrUOt1">EMAIL SIGNUP</EmailSignup>
        <CopyrightText>
          &copy; 2024 Hippie Heart.
        </CopyrightText>
        <CenterContainer></CenterContainer>
        <LineContainer>
          <LineLeft></LineLeft>
          <Image src={heartbeatsImage} alt="Heartbeats" />
          <LineRight></LineRight>
        </LineContainer>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
