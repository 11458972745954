import React from 'react';
import Tier from '../../components/Tier';
import { FlourishImage } from '../../components/Tier';
import Flourish1 from './flourish1.svg';
import Flourish2 from './flourish2.svg';
import TopImage from './TopImage.jpeg';
import BottomImage from './BottomImage.jpeg';
import {
  FullWidthContainer,
  SmallCenterContainer,
} from '../../components/Containers';
import styled from 'styled-components';
import LargeParagraph from '../../components/LargeParagraph';
import Button from '../../components/Button';

const FlourishTopImage = styled(FlourishImage)`
  bottom: -78px;
  width: 230px;
`;

const OriginHeader = styled.h2`
  margin-top: 80px;
`;

const ContactButton = styled(Button)`
  margin-bottom: 90px;
`;

const BottomTier = styled(Tier)`
  margin-bottom: 80px;
`;

const TallPoppies = () => {
  return (
    <>
      <Tier backgroundImage={TopImage}>
        <FullWidthContainer>
          <h2>let's start with a question: are you a tall poppy?</h2>
          <p>
            A tall poppy is an incredibly talented, intelligent and successful
            person who stands out from the crowd. Picture a bright red poppy
            flower among a field of green grass.
          </p>
          <p>
            Unfortunately “tall poppy syndrome” - experiencing criticism or
            resentment by others for their achievements - seems to
            disproportionately affect women. This can happen because people feel
            jealous, threatened, or uncomfortable with someone who is more
            successful than themselves. The criticism can take many forms from
            subtle jabs to open hostility.
          </p>
          <p>
            Let's dismantle the structures that clip poppies and amplify the
            voices that uplift.
          </p>
          <LargeParagraph>
            let's create a world where ambition and achievement are met with
            sunshine, not shade.
          </LargeParagraph>
          <FlourishTopImage flourish={Flourish1} />
        </FullWidthContainer>
      </Tier>
      <Tier>
        <OriginHeader>the origin of this term</OriginHeader>
      </Tier>
      <BottomTier backgroundImage={BottomImage} flourish={Flourish2}>
        <SmallCenterContainer>
          <p>
            The idea of the tall poppy being cut down comes from an ancient
            parable about a field of poppies. In the parable, all the poppies
            were the same height, except for one that grew taller than the rest.
            The other poppies became jealous and conspired to cut it down, so it
            wouldn't stand out any more.
          </p>
        </SmallCenterContainer>
        <ContactButton to='/coaching'>LEARNING MORE ABOUT COACHING</ContactButton>
      </BottomTier>
    </>
  );
};

export default TallPoppies;
