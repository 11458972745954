import { Link } from 'react-router-dom';
import styled from "styled-components";

const Button = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #552d43;
    min-height: 38px;
    border-radius: 5px;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    background: white;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
`;

export default Button;