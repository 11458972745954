import React from "react";
import styled from "styled-components";
import logo from "./logo.png";
import { Link } from "react-router-dom";

const LineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 11px;
  background-color: #552d43;
  margin-top: 7px;
`;

const LineContainerLeft = styled(LineContainer)`
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

const LineLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: -26px;
    width: 15px;
    height: -27%;
    border-top: 11px solid transparent;
    border-right: 12px solid #552d43;
    transform: rotate(180deg);
  }

  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

const LineRight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: -18px;
    width: 15px;
    height: -27%;
    border-top: 10px solid transparent;
    border-right: 11px solid #552d43;
    transform: rotate(270deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  img {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 0;
  }
  span {
    font-size: 15px;
    color: #552d43;
    margin-bottom: 20px;
    @media only screen and (max-width: 750px) {
      display: none;
    }
  }
`;

const Logo = styled.img`
  width: 162px;
  margin-top: -115px;
  @media only screen and (max-width: 750px) {
    margin-top: -115px;
  }
`;

const Header = () => {
  return (
    <Container>
      <LineContainerLeft>
        <LineLeft />
      </LineContainerLeft>
      <CenterContainer>
        <Link to="/">
          <Logo src={logo} alt="Logo Graphic" />
        </Link>
        <span>intuitive career coach</span>
      </CenterContainer>
      <LineContainer>
        <LineRight />
      </LineContainer>
    </Container>
  );
};

export default Header;
