import React from "react";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Coaching from "./pages/Coaching";
import ContactForm from "./pages/ContactForm";
import TallPoppies from "./pages/TallPoppies";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/coaching" element={<Coaching />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/tall-poppies" element={<TallPoppies />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
