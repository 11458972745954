import styled from 'styled-components';
import Tier from '../Tier';

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 50px;
  background: #552d43;
  margin: 0 30px;
  max-width: 1120px;
  width: auto;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    padding: 20px;
  }
  h2 {
    margin-top: 0;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }
`;


export const SmallCenterContainer = styled(CenterContainer)`
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #83454f;
  max-width: 800px;
  p {
    color: white;
  }
`;

export const InfoTier = styled(Tier)`
  padding-bottom: 100px;
  h2 {
    color: #bfa598;
  }
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  min-width: 210px;
  @media only screen and (max-width: 750px) {
    max-width: none;
  }
  h2 {
    color: #bfa598;
  }
  p {
    color: white;
    margin-right: 30px;
    margin-left: 30px;
  }
`;

export const InfoTierHeader = styled.h2`
  color: #28162a !important;
  margin-top: 100px;
`;

export const InfoContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }
`;

export const FullWidthContainer = styled(CenterContainer)`
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
  background: rgba(250, 250, 250, 0.85);
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 50px;
  background: rgba(250, 250, 250, 0.85);
  margin: 95px auto 95px 95px;
  max-width: 45%;
  @media only screen and (max-width: 750px) {
    margin: 20px 30px 80px 30px;
    max-width: 100%;
  }
`;
